import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "card-title"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "list-left"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "list-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_update_time = _resolveComponent("update-time");
  const _component_img_content = _resolveComponent("img-content");
  const _component_section_card = _resolveComponent("section-card");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_t_empty = _resolveComponent("t-empty");
  const _component_t_nav_news = _resolveComponent("t-nav-news");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    "is-white-bg": true,
    "is-header-padding": true,
    class: "search-list"
  }, {
    default: _withCtx(() => {
      var _ctx$seoSearchData, _ctx$seoSearchData3, _ctx$seoSearchData4, _ctx$seoSearchData5, _ctx$seoSearchData6;
      return [_ctx.isSpider ? (_openBlock(), _createElementBlock("h1", _hoisted_1, "出海易资讯文章搜索结果页")) : _createCommentVNode("", true), _createVNode(_component_update_time), _createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [!_ctx.seoSearchDataRes.isLoading && (_ctx$seoSearchData = _ctx.seoSearchData) !== null && _ctx$seoSearchData !== void 0 && _ctx$seoSearchData.items.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_section_card, {
        title: _ctx.keyword
      }, {
        default: _withCtx(() => {
          var _ctx$seoSearchData2;
          return [(_ctx$seoSearchData2 = _ctx.seoSearchData) !== null && _ctx$seoSearchData2 !== void 0 && _ctx$seoSearchData2.items ? (_openBlock(), _createBlock(_component_img_content, {
            key: 0,
            list: _ctx.seoSearchData.items
          }, null, 8, ["list"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["title"]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_el_pagination, {
        "current-page": (_ctx$seoSearchData3 = _ctx.seoSearchData) === null || _ctx$seoSearchData3 === void 0 ? void 0 : _ctx$seoSearchData3.page,
        "page-size": (_ctx$seoSearchData4 = _ctx.seoSearchData) === null || _ctx$seoSearchData4 === void 0 ? void 0 : _ctx$seoSearchData4.page_size,
        layout: "prev, pager, next, jumper",
        total: (_ctx$seoSearchData5 = _ctx.seoSearchData) === null || _ctx$seoSearchData5 === void 0 ? void 0 : _ctx$seoSearchData5.total,
        background: "",
        onCurrentChange: _ctx.handleChangePageaction
      }, null, 8, ["current-page", "page-size", "total", "onCurrentChange"])], 512), [[_vShow, (_ctx$seoSearchData6 = _ctx.seoSearchData) === null || _ctx$seoSearchData6 === void 0 ? void 0 : _ctx$seoSearchData6.total]])], 64)) : !_ctx.seoSearchDataRes.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_t_empty, {
        title: "抱歉未找到您想要的信息",
        "is-change-path": true
      })])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_t_nav_news, {
        "nav-news-cards": _ctx.navCardList
      }, null, 8, ["nav-news-cards"])])])];
    }),
    _: 1
  });
}