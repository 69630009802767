import { defineComponent } from 'vue';
import { handleUrl } from '@/base/utils/url';
export default defineComponent({
  name: 'ImgContent',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  setup(props) {
    const formatTimestamp = time => {
      if (time) {
        return time.split(' ')[0].replace(/\./g, '-');
      }
      return '';
    };
    return {
      formatTimestamp,
      handleUrl
    };
  }
});