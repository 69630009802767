import {IGetSeoListReq, SeoListRes} from '../interface/seolist';
import {API} from '@/base/http';

const PATHS = {
    seolist: '/random',
};

const DEFAULT__NUM = 20;

/**
 * 获取资讯列表
 */
export const getSeoList = (params: IGetSeoListReq) => {
    const {num = DEFAULT__NUM} = params || {};

    return API.get<SeoListRes>(PATHS.seolist, {data: {...params, num}});
};
